
















































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { LETTER_STATES, Project } from '@/model/Project'
import DisplayLetter from '../../components/core/project/DisplayLetter.vue'
import { User } from '@/model/User'
import { ROLES } from '@/model/Role'
import { DigUiPopup } from '@digithia/ui'
import { DigInputController } from '@digithia/input'

@Component({
  components: { DisplayLetter },
})
export default class ViewLetter extends Vue {
  projectId!: number

  editing = false
  embed = ''

  LETTER_STATES = LETTER_STATES

  get letter(): string {
    return this.$store.getters.letter
  }

  get isAdmin(): boolean {
    return this.$store.getters.isAdmin
  }

  get mainProject(): Project {
    return this.$store.getters.mainProject
  }

  get projects(): Project[] {
    return this.$store.getters.projects
  }

  get user(): User {
    return this.$store.getters.user
  }

  get isCreated(): boolean {
    return this.mainProject.letterState === LETTER_STATES.CREATED
  }

  get canSign(): boolean {
    return this.mainProject.letterState === LETTER_STATES.ADMIN_VALIDATED
  }

  get isSigned(): boolean {
    return this.mainProject.letterState === LETTER_STATES.COMPLETED
  }

  isValidating = false
  isCompleting = false

  // openPopup() {
  //   const popup: DigUiPopup | null = document.querySelector('#popup-admin')
  //   popup?.trigger()
  // }

  // closePopup() {
  //   console.log('close popup')
  //   const popup: DigUiPopup | null = document.querySelector('#popup-admin')
  //   popup?.close()
  // }

  validateLetterAdmin() {
    if (!DigInputController.checkInputsValidity('#popup-admin')) {
      return this.$store.dispatch('toaster/toast', {
        message: `Formulaire invalide`,
        type: 'warning',
      })
    }
    this.isValidating = true
    this.$store
      .dispatch('validateLetterAdmin', {
        id: this.projectId,
      })
      .then(json => {
        this.$store.dispatch('toaster/toast', json)
        this.isValidating = false
        // this.closePopup()
      })
  }

  validateLetterUser() {
    this.isValidating = true
    this.$store
      .dispatch('validateLetterUser', { id: this.projectId })
      .then(json => {
        this.$store.dispatch('toaster/toast', json)
        this.isValidating = false
      })
  }

  completeProject() {
    this.isCompleting = true
    this.$store
      .dispatch('completeProject', { id: this.projectId })
      .then(json => {
        this.$store.dispatch('toaster/toast', json)
        this.isCompleting = false
      })
  }

  revertProject() {
    this.isCompleting = true
    this.$store.dispatch('revertProject', { id: this.projectId }).then(json => {
      this.$store.dispatch('toaster/toast', json)
      this.isCompleting = false
    })
  }

  created() {
    const id = +this.$route.params.id
    this.$store.dispatch('getSiblingsProjects', id).then(json => {
      console.log(json)
      this.projectId = json.data.find((p: Project) => p.main)?.id
      this.$store.dispatch('getProjectLetter', this.projectId)
      this.$store.dispatch('getUserProject', this.projectId)
      this.$store.dispatch('getProject', this.projectId)
    })
  }

  @Watch('$route', { immediate: true, deep: true })
  onUrlChange() {
    const id = +this.$route.params.id
    this.$store.dispatch('getSiblingsProjects', id).then(json => {
      console.log(json)
      this.projectId = json.data.find((p: Project) => p.main)?.id
      this.$store.dispatch('getProjectLetter', this.projectId)
      this.$store.dispatch('getUserProject', this.projectId)
      this.$store.dispatch('getProject', this.projectId)
    })
  }

  // goToSigning() {
  //   this.$router.push('sign-letter')
  // }
}
